import { has } from '../conditions';

export interface MessageModel {
  id: string;
  content: string;
  date: any;
  authorName: string;
  authorId: string;
  authorAvatar?: string;
  backgroundColor?: string;
  color?: string;
  showId?: string;
  type?: 'success' | 'warn';
  seats?: MessageSeatModel[];
}

export interface MessageSeatModel {
  id: string;
  row: number;
  number: number;
  alias?: string;
  type: 'ADDED' | 'DELETED' | 'RETAINED';
}

export function isMessageModel(object: any): object is MessageModel {
  return (
    object &&
    has(object).string('id') &&
    has(object).string('content') &&
    has(object).object('date') &&
    has(object).string('authorName') &&
    has(object).string('authorId') &&
    has(object).optional().string('authorAvatar') &&
    has(object).optional().string('backgroundColor') &&
    has(object).optional().string('color') &&
    has(object).optional().string('showId') &&
    has(object).optional().string('type') &&
    has(object).optional().array('seats')
  );
}
